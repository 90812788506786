import Stripe from 'stripe'
import { formatRecurrence } from '~/services/stripe/prices'
import { getUserToken } from '~/services/supabase'
import { plans } from '~/static/stripe'
import { APIResponse } from '~/types/api/response'
import { CancelSubscriptionResponse, UpdateSubscriptionPayload, SubscriptionsResponse, UserSubscriptions, UpdateSubscriptionPreviewResponse } from '~/types/stripe/subscription'

const getSubscriptions = async () => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/current', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      }
    })
    const response: APIResponse<SubscriptionsResponse> = await data.json()
    return response
  }
}

const getFormattedSubscriptions = async () => {
  const subscriptions: UserSubscriptions = {
    individual: null,
    pro: []
  }
  const response = await getSubscriptions()
  if(response && !response.error){
    response.result.forEach(subscription => {
      subscription.items.data.forEach(item => {
        const planId = item.plan.product as string
        const individualPlan = plans.individual.find(plan => plan.productId === planId)
        const proPlan = plans.pro.find(plan => plan.productId === planId)
        const recurrence = formatRecurrence(item.price)
        const schedule = subscription.schedule && typeof subscription.schedule !== 'string' ? subscription.schedule : undefined

        if(individualPlan){
          subscriptions.individual = {
            id: subscription.id,
            plan: individualPlan,
            recurrence,
            cancelled: subscription.cancel_at_period_end,
            periodEnd: new Date(subscription.current_period_end * 1000),
            schedule
          }
        }

        if(proPlan){
          subscriptions.pro.push({
            id: subscription.id,
            plan: proPlan,
            recurrence,
            cancelled: subscription.cancel_at_period_end,
            slots: item.quantity,
            periodEnd: new Date(subscription.current_period_end * 1000),
            schedule
          })
        }
      })
    })
  }
  return subscriptions
}

const getIndieSubscription = async () => {
  const subscriptions = await getSubscriptions()
  const indieSubscription = subscriptions?.result.find(subscription => {
    const isIndie = subscription.items.data.some(item => {
      const planId = item.plan.product as string
      const isIndiePlan = plans.individual.find(plan => plan.productId === planId)
      return isIndiePlan
    })
    return isIndie
  })
  return indieSubscription
}

const getProLicenses = async () => {
  const proSubscription: Stripe.SubscriptionItem[] = []
  const subscriptions = await getSubscriptions()
  subscriptions?.result.forEach(subscription => {
    const isProSubscription = subscription.items.data.some(item => {
      const planId = item.plan.product as string
      const isProPlan = plans.pro.find(plan => plan.productId === planId)
      return isProPlan
    })
    if(isProSubscription){
      proSubscription.push(...subscription.items.data)
    }
  })
  return proSubscription
}

const getIsSubscribed = async () => {
  const subscriptions = await getFormattedSubscriptions()
  const hasSubscriptions = Boolean(subscriptions.individual) || (subscriptions.pro && subscriptions.pro.length > 0)
  return hasSubscriptions
}

const cancelSubscription = async (subscriptionId: string) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/cancel', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify({
        subscriptionId
      })
    })
    const result: CancelSubscriptionResponse = await data.json()
    return result.success
  }
}

const updateSubscription = async ({ subscriptionItems, type }: UpdateSubscriptionPayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/update', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify({
        subscriptionItems,
        type
      })
    })
    const result = await data.json()
    return result
  }
}

const previewSubscriptionUpdate = async ({ subscriptionItems, type }: UpdateSubscriptionPayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/preview-update', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify({
        subscriptionItems,
        type
      })
    })
    const result: UpdateSubscriptionPreviewResponse = await data.json()
    return result
  }
}

export {
  getSubscriptions,
  getFormattedSubscriptions,
  getIndieSubscription,
  getProLicenses,
  getIsSubscribed,
  cancelSubscription,
  updateSubscription,
  previewSubscriptionUpdate
}
import Stripe from 'stripe'
import { translations } from '~/translations'
import { GetLicensesTotalProps, GetRecurrenceIndexProps, GetTieredPriceProps } from '~/types/stripe/license'

const sortByRecurrence = (priceA: Stripe.Price, priceB: Stripe.Price) => {
  const aIntervalInMonths = priceA.recurring?.interval === 'year' ? 12 : 1
  const aCount = (priceA.recurring?.interval_count ?? 1) * aIntervalInMonths
  const bIntervalInMonths = priceB.recurring?.interval === 'year' ? 12 : 1
  const bCount = (priceB.recurring?.interval_count ?? 1) * bIntervalInMonths
  return aCount - bCount
}

const formatRecurrence = (price: Stripe.Price, forEach=false) => {
  const recurring = price.recurring
  const interval = recurring?.interval_count
  const recurrence = recurring?.interval === 'year' 
    ? translations().subscriptions.recurrence.year 
    : (
      interval === 1 
        ? translations().subscriptions.recurrence.singleMonth 
        : translations().subscriptions.recurrence.severalMonths
    )
  let label = ''
  if(forEach){
    const intervalLabel = interval !== 1 ? `${interval} ` : ''
    label = `${translations().subscriptions.recurrence.forEach} ${intervalLabel}${recurrence}`
  }else{
    label = `${interval} ${recurrence}`
  }
  return label
}

const getRecurrenceIndex = ({ recurrence, plan }: GetRecurrenceIndexProps) => {
  const index = plan.prices.findIndex(price => {
    return (price.recurring?.interval === recurrence.interval) && (price.recurring?.interval_count === recurrence.interval_count)
  })
  return index
}

const formatPrice = (price?: number) => `${((price ?? 0)/100).toFixed(2)}€`

const getTieredPrice = ({ price, count }: GetTieredPriceProps) => {
  const tier = price.tiers?.find(entry => count <= (entry.up_to ?? Infinity))
  return tier?.unit_amount ?? 0
}

const getLicensesTotal = ({ plans, subscriptionItems, recurrenceIndex }: GetLicensesTotalProps) => {
  const total = subscriptionItems.reduce((result, subscriptionItem) => {
    const plan = plans.find(plan => plan.plan.id === subscriptionItem.id)
    const price = plan?.prices[recurrenceIndex]
    
    if(price){
      const tieredPrice = getTieredPrice({
        price,
        count: subscriptionItem.count
      })
      result += tieredPrice * subscriptionItem.count
    }

    return result
  }, 0)
  return total
}

export {
  sortByRecurrence,
  formatRecurrence,
  formatPrice,
  getRecurrenceIndex,
  getTieredPrice,
  getLicensesTotal
}